.sidebar-item {
  box-shadow: 8px 0px 4px -3px rgba(0, 0, 0, 0.75);
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
}

.sidebar-item--active {
  border-left: 3px solid red !important;
  background-color: $mirage !important;
}

.sidebar-item {
  &__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__icon {
    margin-right: 6px;
  }

  &__divider {
    margin: 16px auto !important;
    width: 95%;
    background-color: white !important;
  }

  &__arrow {
    font-size: 24px !important;
  }

  &__arrow--expanded {
    color: $fountain-blue;
    font-weight: bold;
  }

  &:hover .sidebar-item__icon {
    background-color: $mirage;
    font-weight: 600;
    color: white;
  }
}

// Import fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700;900&display=swap');

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  background-color: #ebedee;
}

@import 'common';

.product-search {
  input {
    font-size: 14px;
    min-height: 24px;
  }
}

.MuiDataGrid-cell:focus {
  outline: none !important;
}
